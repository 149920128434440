export enum DataHook {
  // Widget
  WidgetWrapper = 'ProfileCard-wrapper',
  SignUpWidget = 'ProfileCard-signUpWidget',
  SignUpWidgetCTA = 'ProfileCard-signUpWidgetCTA',
  HorizontalWidget = 'ProfileCard-horizontalWidget',
  MobileWidget = 'ProfileCard-mobileWidget',
  VerticalWidget = 'ProfileCard-verticalWidget',
  CoverWrapper = 'ProfileCard-coverWrapper',
  HighQualityCover = 'ProfileCard-coverHighQuality',
  MemberName = 'ProfileCard-memberName',
  MemberNameInput = 'ProfileCard-memberNameInput',
  ProfilePhoto = 'ProfileCard-profilePhoto',
  FollowersFollowing = 'ProfileCard-followersFollowing',
  Followers = 'ProfileCard-followers',
  Following = 'ProfileCard-following',
  FollowCTA = 'ProfileCard-followCTA',
  ChangeCoverCTA = 'ProfileCard-changeCoverCTA',
  ChangeCoverPositionCTA = 'ProfileCard-changePositionCoverCTA',
  DragToChangeCoverPositionCTA = 'ProfileCard-dragToChangeCoverPositionCTA',
  ViewPubLicProfileCTA = 'ProfileCard-viewPubLicProfileCTA',
  EditProfileCTA = 'ProfileCard-editProfileCTA',
  SaveProfileCTA = 'ProfileCard-saveProfileCTA',
  JoinCommunityCTA = 'ProfileCard-joinCommunityCTA',
  MoreActionsWrapper = 'ProfileCard-moreActionsWrapper',
  MoreActionsPopover = 'ProfileCard-moreActionsPopover',
  MoreActionsCTA = 'ProfileCard-moreActionsCTA',
  Popover = 'ProfileCard-popover',
  Loader = 'ProfileCard-loader',
  CardCoverSave = 'ProfileCard-cardCoverSave',
  CardCoverCancel = 'ProfileCard-cardCoverCancel',
  // Settings
  SettingsTabs = 'ProfileCard-settingsTabs',
  MainTab = 'ProfileCard-mainTab',
  MainTabButton = 'ProfileCard-mainTabButton',
  MainTabMessage = 'ProfileCard-mainTabMessage',
  MainTabCTA = 'ProfileCard-mainTabCTA',
  MainTabCTAManageBadges = 'ProfileCard-mainTabCTAManageBadges',
  MainTabCTARateMembersArea = 'ProfileCard-mainTabCTARateMembersArea',
  LayoutTab = 'ProfileCard-layoutTab',
  LayoutTabTitle = 'ProfileCard-layoutTabTitle',
  LayoutTabButton = 'ProfileCard-layoutTabButton',
  ProfileLayoutContent = 'ProfileCard-profileLayoutContent',
  ProfileLayoutTitle = 'ProfileCard-profileLayoutTitle',
  ProfileImageLayoutContent = 'ProfileCard-profileImageLayoutContent',
  ProfileImageLayoutTitle = 'ProfileCard-profileImageLayoutTitle',
  ProfileAlignmentContent = 'ProfileCard-profileAlignmentContent',
  ProfileAlignmentTitle = 'ProfileCard-profileAlignmentTitle',
  ProfileImageSizeTitle = 'ProfileCard-profileImageSizeTitle',
  ProfileWidgetSizeTitle = 'ProfileCard-profileWidgetSizeTitle',
  ProfileImageSizeContent = 'ProfileCard-profileImageSizeContent',
  ProfileWidgetSizeContent = 'ProfileCard-profileWidgetSizeContent',
  DisplayTab = 'ProfileCard-displayTab',
  DisplayTabButton = 'ProfileCard-displayTabButton',
  DisplayTabControls = 'ProfileCard-displayTabControls',
  DisplayTabShowFollowers = 'ProfileCard-displayTabShowFollowers',
  DisplayTabShowTitle = 'ProfileCard-displayTabShowTitle',
  DisplayTabShowCover = 'ProfileCard-displayTabShowCover',
  DisplayTabShowMessageButton = 'ProfileCard-displayTabShowMessageButton',
  DisplayTabShowFollowButton = 'ProfileCard-displayTabShowFollowButton',
  DisplayTabShowRole = 'ProfileCard-displayTabShowRole',
  DesignTab = 'ProfileCard-designTab',
  DesignTabButton = 'ProfileCard-designTabButton',
  DesignTabBackButton = 'ProfileCard-designTabBackButton',
  DesignTabList = 'ProfileCard-designTabList',
  DesignTabFooter = 'ProfileCard-designTabFooter',
  DesignTabNote = 'ProfileCard-designTabNote',
  DesignTabResetSettingsButton = 'ProfileCard-designTabResetSettingsButton',
  TextStyleListItem = 'ProfileCard-textStyleListItem',
  ButtonStyleListItem = 'ProfileCard-buttonStyleListItem',
  TextStylePage = 'ProfileCard-textStylePage',
  ButtonStylePage = 'ProfileCard-buttonStylePage',
  TextFontAndColorTitle = 'ProfileCard-textFontAndColorTitle',
  TitleFontColorPicker = 'ProfileCard-titleFontColorPicker',
  BackgroundAndBordersListItem = 'ProfileCard-backgroundAndBordersListItem',
  BackgroundAndBordersPage = 'ProfileCard-backgroundAndBordersPage',
  DefaultCoverControl = 'ProfileCard-defaultCoverControl',
  BadgesTab = 'ProfileCard-badgesTab',
  BadgesTabButton = 'ProfileCard-badgesTabButton',
  TextTab = 'ProfileCard-textTab',
  TextTabButton = 'ProfileCard-textTabButton',
  NoBadgesContent = 'ProfileCard-noBadgesContent',
  NoBadgesMessage = 'ProfileCard-noBadgesMessage',
  NoBadgesLearMoreLink = 'ProfileCard-noBadgesLearMoreLink',
  NoBadgesCTA = 'ProfileCard-noBadgesCTA',
  BadgesSettingsContent = 'ProfileCard-badgesSettingsContent',
  BadgesSettingsTopInfo = 'ProfileCard-badgesSettingsTopInfo',
  BadgesSettingsBottomInfo = 'ProfileCard-badgesSettingsBottomInfo',
  BadgesSettingsCTA = 'ProfileCard-badgesSettingsCTA',
  BadgesLayoutContent = 'ProfileCard-badgesLayoutContent',
  BadgesLayoutTitle = 'ProfileCard-badgesLayoutTitle',
  BadgesLayoutChooseLayout = 'ProfileCard-badgesLayoutChooseLayout',
  BadgesLayoutList = 'ProfileCard-badgesLayoutList',
  BadgesLayoutIconOnlyInfo = 'ProfileCard-badgesLayoutIconOnlyInfo',
  BadgesSize = 'ProfileCard-badgesSize',
  BadgesSizeTitle = 'ProfileCard-badgesSizeTitle',
  BadgesSizeList = 'ProfileCard-badgesSizeList',
  BadgesBackgroundContent = 'ProfileCard-badgesBackgroundContent',
  BadgesBackgroundTitle = 'ProfileCard-badgesBackgroundTitle',
  BadgesShowBackgroundSwitch = 'ProfileCard-badgesShowBackgroundSwitch',
  BadgesCornerRadiusSlider = 'ProfileCard-badgesCornerRadiusSlider',
  BadgesTextContent = 'ProfileCard-badgesTextContent',
  BadgesTextTitle = 'ProfileCard-badgesTextTitle',
  BadgesTextFontPicker = 'ProfileCard-badgesTextFontPicker',
  SupportTab = 'ProfileCard-supportTab',
  SupportTabButton = 'ProfileCard-supportTabButton',
  SupportTabTitle = 'ProfileCard-supportTabTitle',
  SupportTabContactUs = 'ProfileCard-supportTabContactUs',
  SupportTabGoToSupport = 'ProfileCard-supportTabGoToSupport',

  MyAccountDisplayInfo = 'MyAccount-displayInfoSection',
  AboutEditProfileButton = 'Profile-EditProfileButton',
}
